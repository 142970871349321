import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";

import { Layout } from "../components/Layout";
import { Bio } from "../components/02_molecules/Bio";
import { TagList } from "../components/02_molecules/TagList";
import { DescItem } from "../components/02_molecules/DescItem";
import { Header } from "../components/03_organisms/Header";
import { Footer } from "../components/03_organisms/Footer";
import ogPic from "src/assets/images/ogp.png";
import styles from "./index.module.css";

const Home = ({ data }) => {
  const siteMetadata = data.site.siteMetadata;

  const siteTitle = siteMetadata.title;
  const siteUrl = siteMetadata.siteUrl;
  const siteDescription = siteMetadata.description;
  const twitterUsername = siteMetadata.twitterUsername;
  // const googleSiteVer = siteMetadata.google_site_verification
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        meta={[
          { name: "description", content: siteDescription },
          // { name: 'keywords', content: data.site.siteMetadata.keywords},
          { property: "og:title", content: siteTitle },
          { property: "og:description", content: siteDescription },
          { property: "og:url", content: siteUrl },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: siteTitle },
          { property: "twitter:card", content: "summary_large_image" },
          { property: "twitter:site", content: twitterUsername },
          // { property: 'google-site-verification', content: googleSiteVer},
          { property: "og:image", content: siteUrl + ogPic },
        ]}
        title={siteTitle}>
        <script
          data-ad-client="ca-pub-8068277400499023"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <Header isHome={true} />
      <div className={styles.flexRoot}>
        {posts.map(({ node }, index) => {
          const title = node.frontmatter.title || node.fields.slug;
          console.log(node);
          return (
            <DescItem
              slug={node.fields.slug}
              title={title}
              ogpimage={node.frontmatter.ogpimage}
              excerpt={node.excerpt}
              date={node.frontmatter.date}
            />
          );
        })}
      </div>
      <TagList />
      <Bio />
      <Footer />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
        twitterUsername
        google_site_verification
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            ogpimage
          }
        }
      }
    }
  }
`;
export default Home;
